import React from 'react';
import { navigate, graphql } from 'gatsby';

// import { withPrefix }, Link from 'gatsby-link';
import get from 'lodash/get';
import SEO from 'components/SEO/SEO';
import Helmet from 'react-helmet';
// import Img from 'components/Elements/Img';
import logger from 'utils/logger';
import numeral from 'numeral';
import download from 'downloadjs';

import LoadingView from 'components/Loaders/LoadingView';

class ArticleSingle extends React.Component {
  constructor () {
    super();
    this.state = { time: {}, seconds: 3 };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  componentDidMount () {
    const timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  secondsToTime (secs){
    const hours = Math.floor(secs / (60 * 60));

    const divisorMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorMinutes / 60);

    const divisorSeconds = divisorMinutes % 60;
    const seconds = Math.ceil(divisorSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  startTimer (url) {
    logger.verbose(`startTimer (url)`, url);
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(e => this.countDown(url), 1000);
    }
  }

  countDown (url) {
    // Remove one second, set state so a re-render happens.
    const seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds
    });

    // Check if we're at zero.
    if (seconds === 2) {
      download(url);
      logger.verbose(`download(url);`, url);
    }
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.contentfulDownloads
    ) { return <LoadingView />; }
    const downloadFile = data.contentfulDownloads;
    const { url } = downloadFile.resource.file;
    // logger.verbose(`downloadFile`, downloadFile);
    // logger.verbose(`url`, url);

    const user = typeof localStorage !== 'undefined' && JSON.parse(localStorage.getItem('user'));

    let downloadType = downloadFile.type;
    if (downloadType === `document`) {
      downloadType = `support`;
    }
    if (downloadFile.accessLevel && !downloadFile.accessLevel.includes(`public`)) {
      let hasAccess = false;
      if (user) {
        if (user.permissions && user.permissions.groups) {
          user.permissions.groups.forEach((group) => {
            logger.verbose(`user.permissions.groups.forEach((group)`, group);
            if (downloadFile.accessLevel.includes(group)) {
              hasAccess = true;
            }
          });
        }
      }
      if (!hasAccess) {
        logger.error(`Download unauthorized`);
        navigate(`unauthorized`);
      } else {
        logger.verbose(`Download authorized`);
      }
    }

    let icon;
    if (downloadType === `datasheet`) {
      icon = `File-Chart`;
    } else if (
      downloadType === `manual` ||
      downloadType === `support`
    ) {
      icon = `Book`;
    } else if (downloadType === `catalog`) {
      icon = `Address-Book2`;
    } else {
      icon = `Download-fromCloud`;
    }
    let extension = downloadFile.resource.file.contentType.split(`/`).pop();
    if (extension === `x-dosexec`) { extension = `Executable`; }
    if (extension === `octet-stream`) { extension = `Executable`; }
    const urlSplit = downloadFile.resource.file.url.split('/');
    const prettyURL = urlSplit[urlSplit.length - 1];
    
    if (typeof window !== 'undefined') {
      if (this.timer === 0 && this.state.seconds > 0) {
        logger.verbose(`START TIMER`, this.state.seconds);
        this.startTimer(url);
      }
    }
    logger.templateEnd();
    return (
      <div>
        <SEO
          title={downloadFile.name}
          pathname={`/downloads/files/${prettyURL}`}
        />
        {
          extension === 'pdf' ? (
            <Helmet
              meta={[
                {
                  'http-equiv': `refresh`,
                  content: `0;URL='${url}'`
                }
              ]}
            />
          ) : (
            <div className={`ptb-30em prl-30em`}>
              <i className={`icon icon-${icon} fs-25em mb-5em color-primary-500`} />
              <h1 className={`fs-15em`}>{downloadFile.name}</h1>
              <p>File Type: {extension}</p>
              <p>Size: {numeral(downloadFile.resource.file.details.size).format('0.0 b')}</p>
              <hr style={{ width: `3em`, marginLeft: `0` }} />
              {this.state.time.s > 0 ? (
                <p>Starting download in {this.state.time.s} seconds...</p>
              ) : (
                <p>Downloading.</p>
              )}
              <p>
                <small>
                  <span>If your download does not start automatically please </span>
                  <a href="#download" onClick={e => this.startTimer(url)}>click here</a>.
                </small>
              </p>
            </div>
          )
        }
      </div>
    );
  }
}

export const query = graphql`
  query($name: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulDownloads( name: { eq: $name } ){
      id
      name
      type
      accessLevel
      resource{
        file{
          url
          fileName
          contentType
          details{
            size
          }
        }
      }
    }
  }
`;
export default ArticleSingle;
